/**
 * Extracts the message from an unknown error type.
 * @param error Error thrown.
 * @returns Error message.
 */
export function getErrorMessage(error) {
    var message;
    if (error instanceof Error) {
        message = error.message;
    }
    else if (error && typeof error === "object" && "message" in error) {
        message = String(error.message);
    }
    else if (typeof error === "string") {
        message = error;
    }
    else {
        message = "Something went wrong";
    }
    return message;
}
/**
 * Reads the cookie and returns its contents as an object.
 * @param tag - The TagProps object containing the cookie information.
 * @returns An object representing the contents of the cookie.
 * @throws {Error} If the cookie is invalid.
 */
export function readCookie(tag) {
    try {
        if (tag.dcsGetCookie) {
            var cookieString = tag.dcsGetCookie(tag.FPCConfig.name);
            var cookieCrumbs = cookieString
                .split(":")
                .map(function (crumb) { return crumb.split("="); });
            return Object.fromEntries(cookieCrumbs);
        }
    }
    catch (error) {
        throw new Error("Invalid cookie");
    }
}
/**
 * Saves a cookie with the provided storename, object, and cookieMetaData.
 * @param storename - The name of the cookie store.
 * @param object - The object containing the cookie properties.
 * @param cookieMetaData - Additional metadata for the cookie.
 */
function dcsSaveCookie(storename, object, cookieMetaData) {
    var data = Object.keys(object)
        .map(function (key) { return "".concat(key, "=").concat(object[key]); })
        .sort()
        .join(":");
    document.cookie = "".concat(storename, "=").concat(data).concat(cookieMetaData);
}
/**
 * Generates a cookie based on the provided visitor parameters.
 * @param visitorParams - The URLSearchParams object containing the visitor parameters.
 * @param tag - The TagProps object representing the tag.
 * @throws {Error} If the visitor parameters are invalid.
 */
export function generateCookie(visitorParams, tag) {
    var _a;
    var YEAR_IN_MS = 31556926 * 1000;
    var vtid = visitorParams.get("wt_vtid");
    var lv = visitorParams.get("wt_vt_f_tlh");
    var ss = visitorParams.get("wt_vtvs");
    if (!vtid || !lv || !ss)
        throw new Error("Invalid visitor parameters");
    tag.vtid = vtid;
    var cookieName = tag.FPCConfig.name;
    var cookieExpiry = (_a = tag.FPCConfig.expiry) !== null && _a !== void 0 ? _a : new Date().getTime() + YEAR_IN_MS * 2;
    var cookieDomain = tag.FPCConfig.domain;
    var cookiePath = "/";
    var cookieMetaData = "; expires=".concat(cookieExpiry, "; path=").concat(cookiePath).concat(cookieDomain ? "; domain=".concat(cookieDomain) : "");
    var cookieData = { id: vtid, lv: lv, ss: ss };
    dcsSaveCookie(cookieName, cookieData, cookieMetaData);
}
/**
 * Generates query parameters for the WebTrends CDT broadcaster.
 * @param cookieData - The cookie data containing the ID, lv, and ss values.
 * @param plugin - The plugin object that contains broadcast and receiver properties.
 * @returns The generated query parameters as a URLSearchParams object.
 */
export function generateQueryParams(cookieData, plugin) {
    var _a;
    var newQueryParams = new URLSearchParams();
    newQueryParams.append("wt_vtid", cookieData.id ? cookieData.id : "");
    newQueryParams.append("wt_vt_f_tlh", cookieData.lv ? cookieData.lv : "");
    newQueryParams.append("wt_vtvs", cookieData.ss ? cookieData.ss : "");
    // Send the full referring URL if the appids array is not empty.
    // Otherwise, send only the hostname.
    var pageURL = "".concat(window.location.origin).concat(window.location.pathname);
    newQueryParams.append("dcsref", ((_a = plugin.appids) === null || _a === void 0 ? void 0 : _a.length) ? pageURL : window.location.hostname);
    return newQueryParams;
}
