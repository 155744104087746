var _a;
import { generateCookie, getErrorMessage } from "./utils";
(function () {
    window.Webtrends.WebTrendsCdtReceiver = function (tag, plugin) {
        var _a, _b, _c;
        if (Array.isArray(plugin.acceptDomains) && !plugin.acceptDomains.length)
            return;
        var paramName = (_a = plugin === null || plugin === void 0 ? void 0 : plugin.paramName) !== null && _a !== void 0 ? _a : "_wt";
        var encode = (_b = plugin === null || plugin === void 0 ? void 0 : plugin.encode) !== null && _b !== void 0 ? _b : true;
        try {
            var visitorParams = getVisitorParams(window.location.search);
            if (!visitorParams.size)
                return;
            var dcsref_1 = visitorParams.get("dcsref");
            if (!plugin.acceptDomains)
                generateCookie(visitorParams, tag);
            if (dcsref_1 &&
                ((_c = plugin.acceptDomains) === null || _c === void 0 ? void 0 : _c.some(function (domain) { return dcsref_1.includes(domain); })))
                generateCookie(visitorParams, tag);
            removeVisitorParams();
        }
        catch (error) {
            console.error("Webtrends CDT Receiver: ".concat(getErrorMessage(error)));
        }
        /**
         * Retrieves the visitor parameters from the provided query parameters.
         * @param qryParams - The query parameters containing the visitor parameters.
         * @returns The visitor parameters as a URLSearchParams object.
         */
        function getVisitorParams(qryParams) {
            var _a;
            var params = new URLSearchParams(qryParams);
            return new URLSearchParams(encode
                ? decode(params.get(paramName))
                : (_a = params.get(paramName)) !== null && _a !== void 0 ? _a : undefined);
        }
        /**
         * Removes the specified visitor parameter from the current URL.
         */
        function removeVisitorParams() {
            var url = new URL(window.location.href);
            url.searchParams.delete(paramName);
            window.history.replaceState({}, "", url);
        }
    };
})();
/**
 * Decodes a base64 encoded string.
 * @param params - The base64 encoded string to decode.
 * @returns The decoded string, or `undefined` if the input is `null`.
 */
function decode(params) {
    if (!params)
        return undefined;
    return atob(params);
}
/**
 * Loads the WebTrends CDT Receiver.
 * @param {TagProps} tag - The tag properties.
 * @param {ReceiverPluginProps} plugin - The receiver plugin properties.
 */
var cdt_receiver_loader = function (tag, plugin) {
    window.Webtrends.WebTrendsCdtReceiver(tag, plugin);
};
(_a = window.Webtrends) === null || _a === void 0 ? void 0 : _a.registerPlugin("cdt_receiver", cdt_receiver_loader);
